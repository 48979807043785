<template>
  <div>this is home page</div>
</template>

<script>
export default {
  name: 'Home',
}
</script>

<style scoped>

</style>
